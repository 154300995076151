import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parse from "html-react-parser"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LearningBlock, BackgroundColourBlock } from "../styledComponents/overView"
import { 
  ProgramSection, 
  ProgramOverview
} from '../styledComponents/program'
import { StyledButton } from "../styledComponents/button"
import Icon from "../components/icon"
import { BaseModalBackground } from "styled-react-modal";
import {StyledModal } from "../styledComponents/modal"
import HeroBanner from "../components/HeroBanner"
import { HeroDescription, HeroText, HeroHeader } from "../styledComponents/heroBanner"  
import EventList from "../components/eventList";

  function FancyModalButton(data) {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
  
    return (
      <>   
        <StyledButton onClick={toggleModal}>Learn more</StyledButton>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
        >
          <StyledButton onClick={toggleModal}>
           <Icon name="nav" />
          </StyledButton>
          {parse(data.data.programContent)}
        </StyledModal>
      </>
    );
  }
  
  const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
  `;


const Mastery = ({ data }) => {
  return (
    <>
    <Layout hasHero>
      <SEO title={data.wpPage.title} />

      <HeroBanner image="/mastery-hero.jpg" backgroundPosition="-287px 0">
          <HeroText>
            <HeroHeader>
                {data.wpPage.heroSection.kaarmibeingHeroHeading}
            </HeroHeader>
            <HeroDescription>{data.wpPage.heroSection.kaarmibeingHeroLedeText && parse(data.wpPage.heroSection.kaarmibeingHeroLedeText)}</HeroDescription>
            <AnchorLink to="/mastery#overview" stripHash title="Find out how the KaarmiBeing team can help you with your health and wellbeing">Find out more</AnchorLink>
          </HeroText>
      </HeroBanner>

      <ProgramSection id="overview">
        <ProgramOverview>
          <p>This live advanced Mastery workshop is for Level 1 participants that wish to:</p>
          <ul>
            <li>Integrate the concepts and practices in much more depth</li>
            <li>Advance more deeply into the ancient philosophy</li>
            <li>Live group coaching</li>
            <li>Continued momentum towards achieving your goals</li>
            <li>Bring your personal examples and how to help others with implementing the techniques for others</li>
          </ul>
        </ProgramOverview>
      </ProgramSection>

      <BackgroundColourBlock number="1">
          
          <LearningBlock>
            <p>We can use breathing and meditation techniques to achieve different goals in life. The KaarmiBeing advanced meditation and breathing techniques are specifically focused on achieving the following benefits:</p>
              <figure>
              <img src="/static/09533594904da2e05ab5996d1d895a7e/stress.svg" alt="" />
              </figure>
              <div className="overviewWrapper">
                  <ul>
                    <li>Increased vitality</li>
                    <li>Overcome a scattered mind</li>
                    <li>Increased concentration and ability to focus</li>
                    <li>Overcoming intrusive thoughts</li>
                    <li>Overcoming the desires that are influenced by having low vitality and a scattered mind</li>
                  </ul>
              </div>               
          </LearningBlock>
        </BackgroundColourBlock>
        <EventList data={data} page="Mastery" />
    </Layout>
    </>
  )
}

export default Mastery

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "mastery" }) {
      title
      content
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
              publicURL
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      OurProgram {
        programData {
          programContent
          programHeader
          programLede
          svg {
            localFile {
              publicURL
            }
          }
        }
      }
      eventsData {
        events {
          date
          time
          timezone
          fieldGroupName
          location
          price
          shortDescription
        }
      }
    }
  }
`
